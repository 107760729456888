import { type StepDefinition } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { SyncProgress } from 'o365.pwa.modules.client.SyncProgress.ts';
import type { SyncType } from 'o365.pwa.types.ts';

export interface ISyncDefinitionOptions {
    syncType: SyncType;
    title: string;
    buttonText?: string;
    iconClass?: string;
    steps: Array<StepDefinition>;
    runWithoutUI: boolean;
    autoCloseDialogOnSuccess: boolean;
}

export class SyncDefinition {
    public readonly syncType: SyncType;
    public readonly title: string;
    public readonly buttonText?: string;
    public readonly iconClass?: string;
    public readonly steps: Array<StepDefinition>;
    public runWithoutUI: boolean;
    public currentSyncProgress?: SyncProgress;
    public autoCloseDialogOnSuccess: boolean

    constructor(options: ISyncDefinitionOptions) {
        this.syncType = options.syncType;
        this.title = options.title;
        this.buttonText = options.buttonText;
        this.iconClass = options.iconClass;
        this.steps = options.steps;
        this.runWithoutUI = options.runWithoutUI;
        this.autoCloseDialogOnSuccess = options.autoCloseDialogOnSuccess;
    }
}

export interface IOfflineSyncDefinitionOptions {
    title: string;
    buttonText?: string;
    iconClass?: string;
    steps: Array<StepDefinition>;
    runWithoutUI?: boolean;
    autoCloseDialogOnSuccess?: boolean;
}

export class OfflineSyncDefinition extends SyncDefinition {
    constructor(options: IOfflineSyncDefinitionOptions) {
        super({
            title: options.title,
            buttonText: options.buttonText,
            iconClass: options.iconClass,
            steps: options.steps,
            syncType: 'OFFLINE-SYNC',
            runWithoutUI: options.runWithoutUI ?? false,
            autoCloseDialogOnSuccess: options.autoCloseDialogOnSuccess ?? false
        })
    }
}

export interface IOnlineSyncDefinitionOptions {
    title: string;
    buttonText?: string;
    iconClass?: string;
    steps: Array<StepDefinition>;
    runWithoutUI?: boolean;
    autoCloseDialogOnSuccess?: boolean;
}

export class OnlineSyncDefinition extends SyncDefinition {
    constructor(options: IOnlineSyncDefinitionOptions) {
        super({
            title: options.title,
            buttonText: options.buttonText,
            iconClass: options.iconClass,
            steps: options.steps,
            syncType: 'ONLINE-SYNC',
            runWithoutUI: options.runWithoutUI ?? false,
            autoCloseDialogOnSuccess: options.autoCloseDialogOnSuccess ?? false
        })
    }
}
